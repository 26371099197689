import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import StoriesSvc from '../../../services/StoriesSvc';

import { getStoryParams } from '../../../helpers/storyHelpers';
import { compareFilters } from '../../../helpers/helpers';
import { removeDuplicates } from '../../../helpers/removeDuplicates';
import useSizes from '../../../hooks/sizes';
import usePrevious from '../../../hooks/usePrevious';

import Tooltip from '../../Tooltip';
import NewsCard from './NewsCard';
import Scrollable from '../../Shared/Scrollable';
import Preloader from '../../Preloader/index';

import Styles from './styles.module.scss';

const TopNews = () => {
  const { width } = useSizes();
  const [storesData, setStoriesData] = useState({
    loading: true,
    isNewLoading: false,
    stories: [],
  });
  const mountedRef = useRef(true);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const {
    permissions: {
      earliest_search_date: earliestSearchDate,
    },
  } = useSelector((state) => (
    state.subscriptions
  ));
  const storyFilters = useSelector((state) => (
    state.storyFilters
  ));

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const mainAPICall = async (params, oldStories = [], isLast, count = 1) => {
    const { stories, nextPageToken } = await StoriesSvc.getStories(params, earliestSearchDate);
    const newData = removeDuplicates(
      [
        ...(oldStories || []),
        ...stories,
      ],
      'id',
    ).slice(0, 15);

    const makeNewCall = (
      newData.length < 15
      && count < 5
      && (
        nextPageToken !== 'EOD'
        || (nextPageToken === 'EOD' && params.time_filter === 'day1')
      )
    );
    const isNewLoading = !(newData.length >= 15) || (isLast ? makeNewCall : true);
    if (mountedRef.current) {
      setStoriesData({
        loading: false,
        isNewLoading,
        stories: newData,
      });
    }

    if (mountedRef.current) {
      if (makeNewCall) {
        return mainAPICall({
          ...params,
          ...(nextPageToken === 'EOD' && params.time_filter === 'day1' && {
            time_filter: 'week1',
          }),
          ...(nextPageToken !== 'EOD' && { page_token: nextPageToken }),
        }, newData, isLast, (count || 1) + 1);
      }

      // if no data found for selected language fetch top stories for all languages
      if (!newData.length && !params.all_languages) {
        return mainAPICall({
          ...params,
          all_languages: true,
          languages: undefined,
        }, newData, isLast, (count || 1) + 1);
      }
    }
    return newData;
  };

  const prevStoryFilters = usePrevious(storyFilters);
  useEffect(() => {
    if (
      !storyFilters?.conflictsChecked
      || (prevStoryFilters && compareFilters(prevStoryFilters, storyFilters))
    ) {
      return;
    }

    setStoriesData((current) => ({
      stories: current.stories,
      loading: true,
    }));

    const dataFetchTimer = async () => {
      const params = getStoryParams({
        filters: {
          ...storyFilters,
          limit: 20,
          selected_time_filter: 'predefined',
          only_with_images: true,
          time_filter: 'day1',
          order_by: 'top',
          group_similar_stories: true,
          time_period: undefined,
          start_datetime: undefined,
          end_datetime: undefined,
          sentiment_gte: undefined,
          sentiment_lte: undefined,
          query: undefined,
          all_languages: !storyFilters.languages ? true : storyFilters.all_languages,
          categories: 'mp,r,op',
          fields: 'id,title,imageUrls,sentiment,type,author_image_url,author_screen_name,domain_cached_logo_url,domain_cached_large_logo_url,domain_name,publishTime,score,additional_data,url,tweet_id,lowest_premium_plan,uuid_title,uuid,translations,lang',
        },
      });

      const stories = await mainAPICall(params);

      if (mountedRef.current) {
        setStoriesData({
          loading: false,
          isNewLoading: false,
          stories: stories.slice(0, 15),
        });
      }
    };
    dataFetchTimer();
  }, [
    storyFilters,
  ]);

  const { loading } = storesData;

  return (
    <div className={Styles['top-news-wrapper']} ref={ref}>
      <Preloader loading={loading} transform />
      <div className={Styles['top-news-header']}>
        <div className={Styles['top-news-header-title']}>
          Top Stories Globally
        </div>
        <Tooltip
          className={Styles['top-news-header-tooltip']}
          hasBigScreenDesign
        >
          Our machines monitor thousands of sources to bring you the most relevant,
          breaking finance and business news.
        </Tooltip>
      </div>
      <Scrollable
        wrapperClassName={Styles['top-news-stories']}
        arrowClass={Styles['top-news-stories-arrow']}
        hideArrows={width < 1024}
        isAllowFull
        hasDelayScroll={width >= 1024}
        allowAutoScroll={!storesData.loading && !storesData.isNewLoading && inView}
      >
        {storesData.stories.map((story) => (
          <NewsCard
            key={story.id}
            story={story}
          />
        ))}
      </Scrollable>
      <div className={Styles['top-news-more-wrapper']}>
        <Link to="/news" className={Styles['top-news-more-btn']}>
          See More
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
};

export default TopNews;
